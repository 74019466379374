require('malihu-custom-scrollbar-plugin')
export default class ModImagesSlider {
  constructor () {
    this.$this = $('.mod-images-slider')
  }
  init () {
    if (this.$this.length) {
      console.log(123)
      this.addSlick()
    }
  }
  addSlick () {
    console.log(123)
    this.$this.find('.images-slider').mCustomScrollbar({
      axis:"x", // horizontal scrollbar s
      setLeft:"0",
      theme:"dark",
      contentTouchScroll: 1,
      mouseWheel: true
  });
  }
}
new ModImagesSlider().init()
